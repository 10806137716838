@import "~@ng-select/ng-select/themes/default.theme.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Lato, "Helvetica Neue", sans-serif;
  overflow-x: hidden;
}

p,
button,
td,
th,
input,
optgroup,
select,
textarea,
label,
a,
span,
div {
  font-family: "Outfit", sans-serif;
}

h1,
h2,
h3,
h4 {
  font-family: "Outfit", sans-serif;
}

.footer-bar {
  color: white;
  font-size: 20px;
  padding: 15px;
  background-color: #000000;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #ee2b3b !important;
}


.swal2-content {
  font-family: "Outfit", sans-serif;
  
}

.modal-backdrop.show {
  opacity: .8;
}

.swal2-styled.swal2-confirm {
  color: white !important;
  border: none !important;
  padding: 7px 20px !important;
  border-radius: 5px !important;
  margin: 0px 0px !important;
  font-size: 20px !important;
  background-color: #5D3C6C !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  text-transform: capitalize !important;
}

.swal2-styled.swal2-cancel {
  color: #343434 !important;
  border: none !important;
  padding: 7px 20px !important;
  border-radius: 5px !important;
  margin: 0px 0px 0px 7px !important;
  font-size: 20px !important;
  background-color: #D9D9D9 !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  text-transform: capitalize !important;
}
::ng-deep .swal2-close {
  color: red !important; 
  opacity: 0.5; /* Change color of close button */
  font-size: 2.5em !important; /* Adjust the font size */
}
.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
.badge {
  --bs-badge-padding-x: 8px;
  --bs-badge-padding-y: 8px;
  --bs-badge-font-size: 0.75rem;
  --bs-badge-font-weight: 500;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 16px;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}
.circle-span {
  width: 10px; /* Set the desired width for the circle */
  height: 10px; /* Set the desired height for the circle */
  display: inline-block; /* Make sure the circle is inline */
  border-radius: 50%; /* To make the span circular */
  vertical-align: middle; /* Align the circle with the text vertically */
}
::ng-deep .swal2-close:hover {
  opacity: 1;
  color: red !important;  /* Change color on hover */
}
.mat-tooltip {
  font-size: 12px;
}

.fixed-top.header-bar{
  z-index: 1;
}
.d-flex{
  flex-direction: row-reverse ;
}

.searchIcon {
  align-items: center;
  color: #4C3A67;
  /* position: absolute; */
  /* display: flex; */
 
  /* justify-content: flex-end;
  right: 0; */
  
  /* margin-right: 60px; */
  
  /* margin-bottom: 10px; */
}
/* style css */

@media only screen and (max-width: 430px) {
  .mat-paginator-container {
    justify-content: start !important;
  }

  .mat-paginator-range-label {
    margin: 0 32px 0 0px !important;
  }

  .main-block {
    padding: 20px !important;
  }

  th.mat-header-cell {
    font-size: 14px !important;
  }

  td.mat-cell {
    font-size: 14px !important;
  }

  td {
    font-size: 14px !important;
  }
}
.vendor-icon:hover {
  color: white !important ;/* Change color to white on hover */
}

.vendor-icon:active {
  color: white !important ;/* Change color to white on hover */
  /* Change color to white on click */
}